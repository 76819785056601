<template>
  <div
    class="sidebar-box"
    :class="{ 'has-logo': showLogo }"
    :style="{
      backgroundColor:
        settings.sideTheme === 'theme-dark'
          ? variables.menuBackground
          : variables.menuLightBackground,
    }"
  >
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar
      :class="settings.sideTheme"
      wrap-class="scrollbar-wrapper"
      ref="scrollbarWrapper"
    >
      <div class="routerMenuContainer" @mouseleave="leaveIndex">
        <div
          class="routerMenuList"
          ref="routerMenuList"
          style="font-size: 16px"
          v-for="(route, index) in routers"
          :key="route.path + index"
        >
          <div
            class="routerMenuItem"
            v-if="!route.hidden"
            @mouseenter="changeIndex(index)"
            :class="{ active: currentIndex === index }"
          >
            <div class="icon">
              <svg-icon :icon-class="route.meta.icon" style="width: 20px; height: 20px" />
            </div>
            <div class="title" v-if="sidebar.opened">
              {{ route.meta.title }}
            </div>
            <div
              @mouseenter="changeIndex(index)"
              class="submenuContent"
              @mouseleave="leaveIndex"
              ref="submenuContent"
              v-show="currentIndex === index"
            >
              <!-- 二级菜单 -->
              <el-scrollbar>
                <div class="secondaryMenuList" ref="secondaryMenuList">
                  <div
                    v-for="(item_value2, index2) in route.children"
                    :key="item_value2.path + index2"
                  >
                    <div class="secondaryMenuItem" v-if="!item_value2.hidden">
                      <div class="secondaryMenuTitle">
                        {{ item_value2.meta.title }}
                      </div>
                      <!-- 三级菜单 -->
                      <div class="threeLevelMenuList">
                        <div
                          v-for="(item_value3, index3) in item_value2.children"
                          :key="item_value3.path + index3"
                        >
                          <div class="threeLevelMenuItem" v-if="!item_value3.hidden">
                            <div
                              class="threeLevelMenuTitle"
                              @click="
                                toRouter(item_value3.routerPath, item_value3.menuId)
                              "
                            >
                              {{ item_value3.meta.title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>

      <!--  <el-menu
                :default-active="activeMenu"
                :collapse="isCollapse"
                :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
                :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
                :unique-opened="true"
                :active-text-color="settings.theme"
                :collapse-transition="false"
                mode="vertical"
            >
                <sidebar-item
                    v-for="(route, index) in sidebarRouters"
                    :key="route.path  + index"
                    :item="route"
                    :base-path="route.path"
                />
            </el-menu> -->
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";
import lodash from "lodash"; //节流函数

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    routers() {
      return this.sidebarRouters.filter((item) => !item.hidden);
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  data() {
    return {
      currentIndex: undefined,
      timer: undefined, //定时器
    };
  },
  beforeDestroy() {
    document.addEventListener("mousemove", this.leaveIndex); //侦听移出其他地方 关闭 展示更多收银信息弹窗
  },
  methods: {
    changeIndex(index) {
      clearTimeout(this.timer); //清楚定时器
      this.timer = setTimeout(async () => {
        // 在这里执行需要触发的事件
        if (index === this.currentIndex) return;
        this.currentIndex = index;
        await this.$nextTick();
        let submenuContent = this.$refs.submenuContent[index];
        //距离顶部距离
        let offTop = submenuContent.getBoundingClientRect().top;
        //距离底部距离
        let offBottom = window.innerHeight - submenuContent.offsetHeight - offTop;
        if (offTop <= 0) submenuContent.style.top = "50px";
        if (offBottom <= 50) submenuContent.style.bottom = "2px"; //底部距离
        if (this.sidebar.opened) submenuContent.style.left = "150px";
        else submenuContent.style.left = "54px";
      }, 200); // 设置延迟时间为 200 毫秒 防止用户操作过快 小于200毫秒不执行
    },
    // 当鼠标移出时，菜单隐藏
    leaveIndex(e) {
      clearTimeout(this.timer); //清楚定时器
      this.currentIndex = -1;
    },
    //路由跳转
    toRouter(path, menuId) {
      this.$router.push({
        path,
        query: {
          menuId,
        },
      });
      this.currentIndex = -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.routerMenuContainer {
  color: #bfcbd9;
  position: relative;
  height: 100%;
  z-index: 77777777777777;
  .routerMenuList {
    .routerMenuItem {
      height: 48px;
      display: flex;
      align-items: center;
      &.active {
        background-color: #1a1c24;
      }
      .icon {
        width: 50px;
        padding: 15px;
      }
      .title {
        flex-grow: 1;
        line-height: 48px;
        color: #bfcbd9;
        text-align: left;
        margin: 0;
        font-size: 16px;
      }
    }
  }
  .submenuContent {
    position: fixed;
    left: 150px;
    // margin-left: 200px;
    // top: 50px;
    z-index: 77777777777777 !important;
    .secondaryMenuList {
      display: flex;
      background-color: #1a1c24;
      padding: 10px 20px;
      .secondaryMenuItem {
        width: 140px;
        margin-right: 30px;
        .secondaryMenuTitle {
          height: 48px;
          line-height: 48px;
          border-bottom: 1px solid #32343c;
          color: #6f7680;
        }
        .threeLevelMenuItem {
          font-size: 14px;
          line-height: 45px;
          .threeLevelMenuTitle {
            cursor: pointer;
            &:hover {
              color: #409eff;
            }
          }
        }
      }
    }
  }
}
</style>
