var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-box",
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          ref: "scrollbarWrapper",
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "div",
            {
              staticClass: "routerMenuContainer",
              on: { mouseleave: _vm.leaveIndex },
            },
            _vm._l(_vm.routers, function (route, index) {
              return _c(
                "div",
                {
                  key: route.path + index,
                  ref: "routerMenuList",
                  refInFor: true,
                  staticClass: "routerMenuList",
                  staticStyle: { "font-size": "16px" },
                },
                [
                  !route.hidden
                    ? _c(
                        "div",
                        {
                          staticClass: "routerMenuItem",
                          class: { active: _vm.currentIndex === index },
                          on: {
                            mouseenter: function ($event) {
                              return _vm.changeIndex(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [
                              _c("svg-icon", {
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { "icon-class": route.meta.icon },
                              }),
                            ],
                            1
                          ),
                          _vm.sidebar.opened
                            ? _c("div", { staticClass: "title" }, [
                                _vm._v(" " + _vm._s(route.meta.title) + " "),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentIndex === index,
                                  expression: "currentIndex === index",
                                },
                              ],
                              ref: "submenuContent",
                              refInFor: true,
                              staticClass: "submenuContent",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.changeIndex(index)
                                },
                                mouseleave: _vm.leaveIndex,
                              },
                            },
                            [
                              _c("el-scrollbar", [
                                _c(
                                  "div",
                                  {
                                    ref: "secondaryMenuList",
                                    refInFor: true,
                                    staticClass: "secondaryMenuList",
                                  },
                                  _vm._l(
                                    route.children,
                                    function (item_value2, index2) {
                                      return _c(
                                        "div",
                                        { key: item_value2.path + index2 },
                                        [
                                          !item_value2.hidden
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondaryMenuItem",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "secondaryMenuTitle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item_value2.meta
                                                              .title
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "threeLevelMenuList",
                                                    },
                                                    _vm._l(
                                                      item_value2.children,
                                                      function (
                                                        item_value3,
                                                        index3
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              item_value3.path +
                                                              index3,
                                                          },
                                                          [
                                                            !item_value3.hidden
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "threeLevelMenuItem",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "threeLevelMenuTitle",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.toRouter(
                                                                                item_value3.routerPath,
                                                                                item_value3.menuId
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item_value3
                                                                                .meta
                                                                                .title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }